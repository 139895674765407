import {
  Box, Link, SimpleGrid, Stack, Text
} from '@chakra-ui/react';
import * as React from 'react';
import FooterHeading from './FooterHeading';

const LinkGrid = (props) => (
  <SimpleGrid
    columns={{ base: 1, md: 4 }}
    {...props}
  >
    <Box textAlign='left' minW="130px">
      <Text mb="4" color="#ff136d">Web Link</Text>
      <Stack>
        <Link target='_blank' href="https://hovr.site/collections" color="#ffff">Collections</Link>
        <Link target='_blank' href="https://hovr.site/support-tickets" color="#ffff">Support Ticket</Link>
        <Link target='_blank' href="https://referral.hovr.site/" color="#ffff">Referral Program</Link>
      </Stack>
    </Box>

    <Box textAlign='left' minW="130px">
      <Text mb="4" color="#ff136d">Support</Text>
      <Stack textAlign='left' >
        <Link target='_blank' href="https://hovr.site/faqs" color="#ffff">FAQ</Link>
        <Link target='_blank' href="https://hovr.site/help-center" color="#ffff">Help Center</Link>
        <Link target='_blank' href="https://hovr.site/feedback" color="#ffff">Feedback </Link>
      </Stack>
    </Box>

    <Box textAlign='left' minW="130px">
      <Text mb="4" color="#ff136d">Other</Text>
      <Stack>
        <Link target='_blank' href="https://hovr.site/about" color="#ffff">About Us</Link>
        <Link target='_blank' href="https://hovr.site/privacy-policy" color="#ffff">Privacy Policy</Link>
        <Link target='_blank' href="https://hovr.site/terms-conditions" color="#ffff">Terms & Conditions</Link>
      </Stack>
    </Box>

    <Box textAlign='left' minW="150px">
      <Text mb="4" color="#ff136d">Contact Us</Text>
      <Stack>
        <Link href="mailTo:info@hovr.site" color="#ffff">info@hovr.site</Link>
      </Stack>
    </Box>
  </SimpleGrid>
);

export default LinkGrid;
