import { ButtonGroup, IconButton } from '@chakra-ui/react';
import * as React from 'react';
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineYoutube,
} from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
const SocialMediaLinks = props => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton
      as="a"
      target="_blank"
      href="https://twitter.com/HovrSite"
      aria-label="Twitter"
      icon={<FaTwitter fontSize="20px" />}
    />
    <IconButton
      as="a"
      target="_blank"
      href="https://www.facebook.com/hovr.site/"
      aria-label="Facebbok"
      icon={<AiOutlineFacebook fontSize="20px" />}
    />
    <IconButton
      as="a"
      target="_blank"
      href="https://www.youtube.com/watch?v=CMqRqd97rQg"
      aria-label="Youtube"
      icon={<AiOutlineYoutube fontSize="20px" />}
    />
    <IconButton
      as="a"
      target="_blank"
      href="https://www.instagram.com/hovr.site/"
      aria-label="Instagarm"
      icon={<AiOutlineInstagram fontSize="20px" />}
    />
  </ButtonGroup>
);

export default SocialMediaLinks;
